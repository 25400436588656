//bg
var bglangEx = {
    
    /* ---- MOTIVATIONAL MESSAGES ---- */
    msg0: "Пoздравления, ти си първи по време за месеца!",
    msg1: "Пoздравления, ти си първи по време за седмицата!",
    msg2: "Пoздравления, ти си втори по време за месеца!",
    msg3: "Пoздравления, ти си първи по време за деня!",
    msg4: "Пoздравления, ти си втори по време за седмицата!",
    msg5: "Пoздравления, ти си трети по време за месеца!",
    msg6: "Пoздравления, ти си първи по време за деня!",
    msg7: "Пoздравления, ти си втори по време за седмицата!",
    msg8: "Пoздравления, ти си трети по време за месеца!",
    msg9: "Пoздравления, ти си трети по време за седмицата!",
    msg10: "Пoздравления, ти си втори по време за деня!",
    msg11: "Пoздравления, ти си трети по време за деня!",
    msg12: "Поздравления! Успя да се справиш без нито една подсказка!",
    msg13: "Поздравления! Справи се от първия път!",
    
    viewRightAnswer: "Разгледайте вярното решение и когато сте готови преминете напред!",
    completeGoalMsg: 'Вие завършихте целта си!',
    goalGreet: 'Поздравления! Изпълнихте целта си за деня!',
    chooseNewGoal: 'Избери си нови цели за този курс!',
    daily_goal : 'Дневна цел',
    goal_no_from_course:'Този курс не е от дневната цел!',
    change_goal_profile:'Смени целта в профила си!',
    divTitle: 'Избери си цели',
    shrinkMSG: 'Свий панела',
    expandMSG: 'Разпъни панела',
    mcTestRight: "Това е верен отговор, но защо?! :)",
    mcTestWrong: "Това е грешен отговор, но защо?!",
    
    correctTest: "Верен резултат!",
    wrongTest: "Грешен резултат!",
    
    missingLangHeading: "Непреведен курс",
    missingLangText: "За съжаление, този курс не е преведен на желания от Вас език. Курсът ще стартира на английски език.",
    unfinishedHeading: "Незавършен курс",
    unfinishedText: "За съжалие този курс не е завършен.",
    notYourLanguageText: "За съжаление, този курс не е преведен на желания от Вас език.",
    speedNormal: "Нормална",
    speedFast: "Бърза",
    speedSuperFast: "Светкавична",
    sureSpeedNormal: "Сигурен ли си че искаш да преминеш към нормална скорост?",
    sureSpeedFast: "Сигурен ли си че искаш да преминеш към бърза скорост?",
    sureSpeеdSuperFast: "Сигурен ли си че искаш да преминеш към светкавична скорост?",
    runCode:'Давай',
    blockMove:'С това блокче се придвижваме на напред',
    blockJump:'this block jump',
    blockSuperJump:'superJump',
    blockStepUp:'stepUp',
    blockStepDown:'stepDown',
    blockPush:'push',
    blockPull:'pull',
    blockTurn:'turn',
    blockGet:'get',
    blockDrop:'drop',
    blockBoat:'move boat',
    blockSetActive:'set active',
    blockTurnto:'turn to',
    blockPlayer:'player',
    blockNear:'near',
    blockSee:'see',
    blockLadder:'on ladder',
    blockWithDo:'with do',
    blockgGoto:'goto',
    blockIs:'is',
    editorsRescrict:"Ограничението за брой редове е ",
    blockly_msg: "Функцията бе променена,трябва да изкарате блокчетата от менюто",
    seeAgain:"Режим на разглеждане",
     buy_label_restiction:"Трябва да закупиш ",
    bow:"Арбалет",
    sword:"Meч",
    shield:"Броня",
    magic_jump:"Магически скок",
    no_ruby_for_sword:"Нямате достатъчно рубини за закупуване на меч!",
    no_ruby_for_bow:"Нямате достатъчно рубини за закупуване на арбалет!",
    no_coin_magic_jump:"Нямате достатъчно монети за закупуване на магически скок!",
    no_coin_for_shield:"Нямате достатъчно монети за закупуване на броня!",
     click_category:"Тук можеш да видиш блокчетата за визуално програмиране",
     clickTest:"Кликни Бутона Тест за да изпълниш кода!",
     clickContinue:'Кликни Бутона мини напред <span class="contExample"><span class="fa-angle-double-right"></span></span>, за да преминеш към следващото упражнение',
     clickHints:"Ако изпитваш затруднения отвори подсказките",
     clickCode:"Кликни за да отвориш кодовия редактор",
     clickInstructions:"Прочети инструкциите за да завършиш нивото",
      clickInfo:"Кликни за да прочетеш теорията и инструкциите",
      continue:'Мини напред'
    
    };
