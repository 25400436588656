//bg
var bglangPage = {
    hideAcord: 'Свий съдържанието',
    showAcord: 'Разпъни цялото съдържанието',
    messageChange: 'Променете паролата',
    messageNoChange: 'Не променяйте паролата',
    invalidMail: 'Невалиден мейл!',
    successfulSubscribe: 'Благодарим за записването!',
    messageCon: 'За да продължите трябва да сте завършили предните упражнения.',
    ticketStatus: 'Статус:',
    statusOpen: 'Отворен',
    statusClose: 'Затворен',
    paymentSuccess1: 'Благодарим ви за покупката!',
    paymentSuccess2: 'Вашето плащане беше успешно!',
    paymentFail: 'Неуспешно плащане',
    sign_require: "Трбява да влезете като потребител за да продължите",
    roleStudent:"ученик",
    roleTeacher:"учител",
    continue:"Продължи",
    continueLogin:"Трябва да сте влезли в системата, за да продължите",
    billUnfinished:"Имате незавършено плащане с ",
    billProblem:" Какъв е проблемът?",
    offerText:"Нашето предложение за теб е",
    showSystem:"Нека ти покажем колко е лесно ученето с нашата система",
    feedback:'Бихте ли ни оставили feedback',
    feedBackTitle:"Feedback",
    startCourse:"Започни курса",
    continueCourse:"Продължи курса",
    finishedCourse:"Курсът е завършен",
    feedbackSuccess:"Получихме вашия feedback. Благодарим ви за отделеното време!"
    };
