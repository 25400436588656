//bg
var bgblockly = {
    repeatField: "повтори х",
    turnRightField: "завой на дясно",
    turnLeftField: "завой на ляво",
    moveField: "движи се",
    turnField: "завърти",
    collect: 'събери',
    eat: 'изяж',
    turn: 'обърни се',
    turn_to: 'обърни се към: ',
    move: 'върви',
    answerBlock: 'Отговор: ',
    jump: 'скочи',
    jump_forward: 'супер скок',
    armour: 'броня',
    wait: 'изчакай',
    get: 'вземи',
    drop: 'пусни',
    player: 'Герой: ',
    goto: 'отиди до: ',
    step_up: 'качи се по стълбата',
    step_down: 'слез по сълбата',
    push: 'бутни',
    pull: 'дръпни',
    move_boat: 'отплавай',
    answer: 'отговор',
    unlock: 'отключи',

    Decimiter: 'дециметър',
    DecimiterAbbr: 'дм',
    Centimeter: 'сантиметър',
    CentimeterAbbr: 'см',
    Meter: 'метър',
    MiterAbbr: 'м',

    CatActions: 'Действия',
    CatConditions: 'Условия',
    CatLoops: 'Цикли',
    CatVariables: 'Променливи',
    CatFunctions: 'Функции'
};